<template>
  <div class="page">
    <h2>搜索商品</h2>
    <chooseGoods chooseGoods @handleChange="handleChange"/>
    <h2>商品详情</h2>
    <el-form label-width="120px" label-position="left">
      <el-form-item label="">
        <tinymce v-model="goodsDetail" placeholder="请输入编辑器" :height="600" />
      </el-form-item>
    </el-form>
    <div class="fucView">
      <el-button type="primary" :disabled="choosedGoodsList.length == 0" @click="save">保存</el-button>
    </div>
  </div>
</template>

<script>
import chooseGoods  from "@/views/tools/components/chooseGoods";
import {apiModfiyGoodsDetail} from "@/request/api";
export default {
  name: "setGoodsDetail",
  components: {
    chooseGoods
  },
  data() {
    return {
      goodsDetail: '',
      choosedGoodsList: [],
    };
  },
  methods: {
    handleChange(value) {
      this.choosedGoodsList = value;
    },
    save(){
      var ids = [];
      this.choosedGoodsList.forEach(item => {
        ids.push(item.goods_id);
      });
      apiModfiyGoodsDetail({ids:ids,descr:this.goodsDetail}).then(res => {
        if(res.code == 200){
          this.$message.success(res.message);
        }
      });

    }
  },
}
</script>

<style scoped>
.page {
  background-color: #FFF;
  padding: 20px;
  /*margin: 20px;*/
}
.fucView {
  display: flex;
  align-items: center;
  margin-left: 120px;
}
.el-form-item {
  margin-bottom: 10px;
}
</style>